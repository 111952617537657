export const enum AccessEnum {
  ADMIN = 'admin',

  SEARCH = 'search',
  DOWNLOAD = 'download',
  TRANSFERS = 'transfers',

  EVENTS_VIEW = 'events_view',
  EVENTS_EDIT = 'events_edit',

  QUEUE_VIEW = 'queue_view',
  QUEUE_EDIT = 'queue_edit',

  FAVORITE_HUBS_VIEW = 'favorite_hubs_view',
  FAVORITE_HUBS_EDIT = 'favorite_hubs_edit',

  SETTINGS_VIEW = 'settings_view',
  SETTINGS_EDIT = 'settings_edit',

  FILESYSTEM_VIEW = 'filesystem_view',
  FILESYSTEM_EDIT = 'filesystem_edit',

  HUBS_VIEW = 'hubs_view',
  HUBS_EDIT = 'hubs_edit',
  HUBS_SEND = 'hubs_send',

  PRIVATE_CHAT_VIEW = 'private_chat_view',
  PRIVATE_CHAT_EDIT = 'private_chat_edit',
  PRIVATE_CHAT_SEND = 'private_chat_send',

  FILELISTS_VIEW = 'filelists_view',
  FILELISTS_EDIT = 'filelists_edit',

  VIEW_FILE_VIEW = 'view_file_view',
  VIEW_FILE_EDIT = 'view_file_edit',
}

export interface WebUserBase {
  id: string;
  username: string;
  permissions: string[];
}

export interface WebUserInput extends WebUserBase {
  password: string;
}

export interface WebUser extends WebUserBase {
  active_sessions: number;
  last_login: number;
}
