// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form.header {
  margin-top: 20px;
}

.Select {
  margin-bottom: 10px;
}

.Select-input > input {
  padding: inherit !important;
  border: inherit !important;
}

.Select-control {
  padding: 2px 2px 2px 2px;
}

.Select-control > a:first-child {
  margin-left: 5px !important;
}

.Select-placeholder {
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  line-height: 35px;
  padding-top: 2px;
}

.Select--multi .Select-input {
  margin-left: 7px !important;
}

.form fieldset {
  border: 0;
  margin: 0;
  border: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/form/style.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,2BAA2B;EAC3B,0BAA0B;AAC5B;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,iEAAiE;EACjE,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,SAAS;EACT,SAAS;EACT,SAAS;AACX","sourcesContent":[".form.header {\n  margin-top: 20px;\n}\n\n.Select {\n  margin-bottom: 10px;\n}\n\n.Select-input > input {\n  padding: inherit !important;\n  border: inherit !important;\n}\n\n.Select-control {\n  padding: 2px 2px 2px 2px;\n}\n\n.Select-control > a:first-child {\n  margin-left: 5px !important;\n}\n\n.Select-placeholder {\n  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;\n  line-height: 35px;\n  padding-top: 2px;\n}\n\n.Select--multi .Select-input {\n  margin-left: 7px !important;\n}\n\n.form fieldset {\n  border: 0;\n  margin: 0;\n  border: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
