// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#mobile-layout .site-content {
  height: 100%;
  padding-top: 55px;
}

#mobile-layout .site-content > .ui.message {
  margin: 10px 5px;
}

/* Site header menu */
#mobile-layout .site-header {
  height: 55px;
}

#mobile-layout .site-header .header-content .label {
  margin-left: 0px;
}

/* Navigation menu */
#mobile-menu {
  z-index: 100 !important;
  max-width: 200px;
  padding-bottom: 10px;
}

#mobile-menu i.navigation.icon {
  float: none !important;
  margin: 0 0.5em 0 0 !important;
}

#mobile-menu .separator {
  height: 20px;
}

/* Semantic has issues with right vertical menu */
#mobile-menu .ui.dropdown.right .menu {
  right: 100%;
  border-radius: 0.28571429rem 0 0.28571429rem 0.28571429rem;
  left: auto !important;
}

#mobile-menu .icon-panel {
  display: flex;
  margin: 10px 20px 10px 20px;
  align-items: baseline;
}

#mobile-menu .icon-panel .touch-icons {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  align-items: center;
}

#mobile-menu .icon-panel .touch-icons > .icon {
  margin: 0px 4px !important;
}

#mobile-menu .icon-panel i {
  float: none !important;
}

/* Dialogs */
.download-dialog .ui.dropdown a.item {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/mobile.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;AAClB;;AAEA,qBAAqB;AACrB;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB;;AAEA,oBAAoB;AACpB;EACE,uBAAuB;EACvB,gBAAgB;EAChB,oBAAoB;AACtB;;AAEA;EACE,sBAAsB;EACtB,8BAA8B;AAChC;;AAEA;EACE,YAAY;AACd;;AAEA,iDAAiD;AACjD;EACE,WAAW;EACX,0DAA0D;EAC1D,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,2BAA2B;EAC3B,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,OAAO;EACP,mBAAmB;AACrB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,sBAAsB;AACxB;;AAEA,YAAY;AACZ;EACE,wBAAwB;EACxB,8BAA8B;EAC9B,mBAAmB;AACrB","sourcesContent":["#mobile-layout .site-content {\n  height: 100%;\n  padding-top: 55px;\n}\n\n#mobile-layout .site-content > .ui.message {\n  margin: 10px 5px;\n}\n\n/* Site header menu */\n#mobile-layout .site-header {\n  height: 55px;\n}\n\n#mobile-layout .site-header .header-content .label {\n  margin-left: 0px;\n}\n\n/* Navigation menu */\n#mobile-menu {\n  z-index: 100 !important;\n  max-width: 200px;\n  padding-bottom: 10px;\n}\n\n#mobile-menu i.navigation.icon {\n  float: none !important;\n  margin: 0 0.5em 0 0 !important;\n}\n\n#mobile-menu .separator {\n  height: 20px;\n}\n\n/* Semantic has issues with right vertical menu */\n#mobile-menu .ui.dropdown.right .menu {\n  right: 100%;\n  border-radius: 0.28571429rem 0 0.28571429rem 0.28571429rem;\n  left: auto !important;\n}\n\n#mobile-menu .icon-panel {\n  display: flex;\n  margin: 10px 20px 10px 20px;\n  align-items: baseline;\n}\n\n#mobile-menu .icon-panel .touch-icons {\n  display: flex;\n  justify-content: flex-end;\n  flex: 1;\n  align-items: center;\n}\n\n#mobile-menu .icon-panel .touch-icons > .icon {\n  margin: 0px 4px !important;\n}\n\n#mobile-menu .icon-panel i {\n  float: none !important;\n}\n\n/* Dialogs */\n.download-dialog .ui.dropdown a.item {\n  display: flex !important;\n  justify-content: space-between;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
