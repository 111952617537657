// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.file-browser .table-container {
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
}

.file-browser {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.file-browser .table td:first-child .file-name {
  float: left;
  max-width: calc(100% - 25px);
}

.file-browser .table td:first-child .file-name.selected {
  font-weight: bold;
}

.file-browser .table td:first-child > i {
  float: right;
}

.file-browser .table th:first-child {
  width: 80%;
}

.file-browser a:hover {
  cursor: pointer;
  text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/components/filebrowser/style.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,OAAO;AACT;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,WAAW;EACX,4BAA4B;AAC9B;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,eAAe;EACf,0BAA0B;AAC5B","sourcesContent":[".file-browser .table-container {\n  overflow-y: auto;\n  overflow-x: hidden;\n  flex: 1;\n}\n\n.file-browser {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n}\n\n.file-browser .table td:first-child .file-name {\n  float: left;\n  max-width: calc(100% - 25px);\n}\n\n.file-browser .table td:first-child .file-name.selected {\n  font-weight: bold;\n}\n\n.file-browser .table td:first-child > i {\n  float: right;\n}\n\n.file-browser .table th:first-child {\n  width: 80%;\n}\n\n.file-browser a:hover {\n  cursor: pointer;\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
