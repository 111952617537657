// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#sidebar {
  z-index: 10;
  width: 100%;
  background-color: #1b1c1d;
  position: absolute !important; /* https://github.com/bokuweb/react-resizable-box/issues/44 */
  padding: 5px 80px 5px 5px;
}

#sidebar-container {
  height: 100%;
  background-color: white;
  border-top-left-radius: 0.28571429rem;
  border-bottom-left-radius: 0.28571429rem;
}

/* Internal class from re-resizable, causes height issues */
*[class^='__resizable'] {
  display: none;
}

.animating.ui.overlay.sidebar,
.ui.visible.overlay.sidebar {
  transition: transform 250ms !important;
}
`, "",{"version":3,"sources":["webpack://./src/routes/Sidebar/style.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,WAAW;EACX,yBAAyB;EACzB,6BAA6B,EAAE,6DAA6D;EAC5F,yBAAyB;AAC3B;;AAEA;EACE,YAAY;EACZ,uBAAuB;EACvB,qCAAqC;EACrC,wCAAwC;AAC1C;;AAEA,2DAA2D;AAC3D;EACE,aAAa;AACf;;AAEA;;EAEE,sCAAsC;AACxC","sourcesContent":["#sidebar {\n  z-index: 10;\n  width: 100%;\n  background-color: #1b1c1d;\n  position: absolute !important; /* https://github.com/bokuweb/react-resizable-box/issues/44 */\n  padding: 5px 80px 5px 5px;\n}\n\n#sidebar-container {\n  height: 100%;\n  background-color: white;\n  border-top-left-radius: 0.28571429rem;\n  border-bottom-left-radius: 0.28571429rem;\n}\n\n/* Internal class from re-resizable, causes height issues */\n*[class^='__resizable'] {\n  display: none;\n}\n\n.animating.ui.overlay.sidebar,\n.ui.visible.overlay.sidebar {\n  transition: transform 250ms !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
