// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.adjustable-speed-limit.popup.trigger {
  border-bottom: 1px dotted;
  width: fit-content;
}

.limiter.popup .remove.button {
  margin-top: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/speed-limit/style.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".adjustable-speed-limit.popup.trigger {\n  border-bottom: 1px dotted;\n  width: fit-content;\n}\n\n.limiter.popup .remove.button {\n  margin-top: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
