// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-grid {
  margin: 0px !important;
  height: 100%;
}

.login-grid .column {
  max-width: 650px;
  width: 95% !important;
}
`, "",{"version":3,"sources":["webpack://./src/routes/Login/style.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,qBAAqB;AACvB","sourcesContent":[".login-grid {\n  margin: 0px !important;\n  height: 100%;\n}\n\n.login-grid .column {\n  max-width: 650px;\n  width: 95% !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
