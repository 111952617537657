// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Add a few semantic styles manually because of different layout */

.ui.action.input .react-autosuggest__container input {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right-color: transparent !important;
}

.ui.input.fluid .react-autosuggest__container,
.ui.input.fluid .react-autosuggest__container input {
  width: 100% !important;
  flex-direction: column;
}

.ui.input.fluid .react-autosuggest__container input:focus {
  border-color: #85b7d9;
  border-right-color: #85b7d9 !important;
}

.react-autosuggest__container ul {
  position: absolute;
  padding: 0px;
  z-index: 998;
}

.react-autosuggest__container ul .description {
  padding-top: 2px;
  color: rgba(0, 0, 0, 0.7);
  font-size: small;
}
`, "",{"version":3,"sources":["webpack://./src/components/autosuggest/base/style.css"],"names":[],"mappings":"AAAA,mEAAmE;;AAEnE;EACE,qCAAqC;EACrC,wCAAwC;EACxC,0CAA0C;AAC5C;;AAEA;;EAEE,sBAAsB;EACtB,sBAAsB;AACxB;;AAEA;EACE,qBAAqB;EACrB,sCAAsC;AACxC;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,yBAAyB;EACzB,gBAAgB;AAClB","sourcesContent":["/* Add a few semantic styles manually because of different layout */\n\n.ui.action.input .react-autosuggest__container input {\n  border-top-right-radius: 0 !important;\n  border-bottom-right-radius: 0 !important;\n  border-right-color: transparent !important;\n}\n\n.ui.input.fluid .react-autosuggest__container,\n.ui.input.fluid .react-autosuggest__container input {\n  width: 100% !important;\n  flex-direction: column;\n}\n\n.ui.input.fluid .react-autosuggest__container input:focus {\n  border-color: #85b7d9;\n  border-right-color: #85b7d9 !important;\n}\n\n.react-autosuggest__container ul {\n  position: absolute;\n  padding: 0px;\n  z-index: 998;\n}\n\n.react-autosuggest__container ul .description {\n  padding-top: 2px;\n  color: rgba(0, 0, 0, 0.7);\n  font-size: small;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
